import React, { useEffect, useState } from "react";
import FooterFacebook from "../Footers/FooterFacebook";
import FooterCasting from "../Footers/FooterCasting";
import axios from "axios";
import GalleryItem from "./GalleryItem";

const Gallery = ({
  title,
  footerType,
  color,
  index,
  component,
  page,
  isFb = false,
}) => {
  let bgColor = color === "green" ? "bg-green" : "bg-white";
  let sColor = color === "green" ? "#F2F2F2" : "";

  const [fbWidth, setFbWidth] = useState(400);
  const [displayFb, setDisplayFb] = useState(false);
  const [pageGallery, setPageGallery] = useState(1);
  const [images, setImages] = useState([]);
  const [tempImages, setTempImages] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await axios.get(
        "https://gplusproduction.pl/services/cms_object_data_edit.php?page=" +
          page +
          "&component=" +
          component
      );
      if (isFb) setTempImages(response.data);
      else setImages(response.data);
    })();
  }, []);

  useEffect(() => {
    if (isFb) {
      window.addEventListener(
        "resize",
        function (event) {
          if (window.innerWidth > 1999) setFbWidth(500);
          if (window.innerWidth < 2000) setFbWidth(400);
          if (window.innerWidth < 1400 && window.innerWidth > 991)
            setFbWidth(300);
        },
        true
      );
    }
  }, []);

  const setFb = () => {};

  useEffect(() => {
    if (tempImages.length > 0) getFb();
  }, [tempImages]);

  const getFb = async () => {
    if (isFb) {
      let response = await axios.get(
        "https://gplusproduction.pl/services/fbPost.php"
      );
      if (response.status == 200 && response.data.id) {
        let arr = [...tempImages];
        arr.unshift({
          id: response.data.id,
          page: 302124596538493,
        });
        setDisplayFb(true);
        setImages(arr);
      }
    }
  };

  const nextPage = () => {
    let maxPage = Math.ceil(images.length / 3);
    if (pageGallery + 1 > maxPage) setPageGallery(1);
    else setPageGallery(pageGallery + 1);
  };

  return (
    <section
      className={`news ${bgColor}`}
      style={{
        width: "100vw",
        minHeight: "100vh",
        paddingTop: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id={`gallerybox_${index}`}
        className="container-fluid trans-appear"
        style={{ paddingTop: "2.5rem", paddingBottom: "2.5rem" }}
      >
        <div className="news__header d-flex w-100">
          <div
            className="news__header-title mr-auto tk-nimbus-sans-condensed"
            style={{ textTransform: "uppercase", textAlign: "left" }}
          >
            {title}
          </div>
          {images.length > 3 ? (
            <div className="news__header-paggination d-flex">
              <div>
                {pageGallery}/{Math.ceil(images.length / 3)}
              </div>
              <div className="news__header-paggination-arrow">
                <img
                  src={require("../../assets/img/arrow-right.svg")}
                  alt="arrow-right"
                  onClick={() => nextPage()}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          {images.map((image, n) => {
            let maxIndex = pageGallery * 3;
            let minIndex = maxIndex - 3;
            const display = n < minIndex || n > maxIndex - 1;
            if (displayFb && n === 0) {
              let postId = image.id.substring(image.id.lastIndexOf("_") + 1);
              let iframeSRC = `https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FGPlusProduction%2Fposts%2F${postId}%2F&show_text=true&adapt_container_width=true&width=${fbWidth}&show_facepile=true`;
              let displayFb = pageGallery === 1;
              let mxWidth = fbWidth === 300 ? 400 : fbWidth;
              return (
                <div
                  className="col-lg-4 col-md-12 mb-5"
                  key={image.id}
                  style={{
                    textAlign: "center",
                    display: displayFb ? "block" : "none",
                  }}
                >
                  <iframe
                    title="postFbGPlus"
                    src={iframeSRC}
                    style={{
                      border: "none",
                      overflow: "hidden",
                      height: "500px",
                      maxWidth: mxWidth + "px",
                      width: "100%",
                    }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </div>
              );
            } else {
              return (
                <GalleryItem
                  key={n}
                  index={index}
                  no={n + 1}
                  sColor={sColor}
                  color={color}
                  src={image.src}
                  href={image.url}
                  title={image.title}
                  description={image.description}
                  display={display}
                  isFb={isFb}
                />
              );
            }
          })}
        </div>
        {footerType === 2 ? (
          <FooterCasting color={color} />
        ) : (
          <FooterFacebook />
        )}
      </div>
    </section>
  );
};
export default Gallery;
